import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="hero is-fullheight is-bold">
      <div className="hero-body">
        <div className="container has-text-centered">
          <Link to={"/"}>
            <h1 className="title">Nothing here...</h1>
          </Link>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
